import React, { useState } from 'react';
import { Tldraw, createTLStore, defaultShapeUtils, uniqueId } from "tldraw";
import './board.css';
import { DocShapeUtil } from "./customComponents/embeddedDocument/doc-shape-component";
import { DocShapeTool } from "./customComponents/embeddedDocument/docs-shape-tool";
import { components, customAssetUrls, uiOverrides } from "./customComponents/ui-overrides";
const data = require('./board.json');

const customShapeUtils = [DocShapeUtil]
const customTools = [DocShapeTool]

export default function Board () {

	const boardId = uniqueId()

	const [store] = useState(() => {
		// Create the store
		const newStore = createTLStore({
			shapeUtils: [...customShapeUtils, ...defaultShapeUtils],
		})

		// Get the snapshot
		const snapshot = data

		// Load the snapshot
		newStore.loadSnapshot(snapshot)

		return newStore
	})

  return (
	<div id="tldraw">
		<Tldraw 
			shapeUtils={customShapeUtils}
			// Pass in the array of custom tools
			tools={customTools}
			store={store}
			// Pass in any overrides to the user interface
			overrides={uiOverrides}
			assetUrls={customAssetUrls}
			// pass in the new Keyboard Shortcuts component
			components={{...components,HelpMenu:()=>(<span style={{fontWeight:700,marginRight:10}}>Cmd + s to save</span>)}}
			onMount={(editor) => {
				editor.getInitialMetaForShape = (shape) => {
						return { boardId: boardId}
				}
			}}
		/>
	</div>
  )
}