import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['code-block'],
      ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'script','code-block'
]

export default function Doc() {
  const [value, setValue] = useState('<h2>Well You can pretty much do anything, Except like doing the dishes. Start typing and uploading shiz</h2>\n<p>Disabled image upload to prevent image dump into server (nsfw welcomed though)</p>');

  function handleChange(content, delta, source, editor) {
    setValue(editor.getContents());
  }

  return (
    <div style={{flex:1, height:'100%'}}>
      <ReactQuill theme="snow" value={value} modules={modules} formats={formats} onChange={handleChange} />
      <span style={{position:'fixed', bottom:5, right:5, fontSize:12, fontWeight:700}}>Cmd + s to save</span>
    </div>
  )
  
}