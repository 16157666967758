import { Button, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import './styles/task.css';
const { Text } = Typography;


export default function Task(props){

    const [edit,setEdit] = useState(false)
    const [taskContent,setTaskContent] = useState(props.task.content)

    useEffect(()=>{
        setTaskContent(props.task.content)
    },[props.task.content])

    const handleKeyDown = async (event) => {
		if (event.key === "Enter") {
		  event.preventDefault(); // Prevent the default browser behavior (saving the webpage)
		  // Call your custom function here
		  
          //Update task
          props.updateTask(props.task.id,taskContent)

          setEdit(false)
	    }
	}

    const handleOnBlur = async () =>{
        props.updateTask(props.task.id,taskContent)
        setEdit(false)
    }

    return (
        <Draggable draggableId={props.task.id} index={props.index}>
            {
                provided => (
                    <div 
                        className='task'
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        <Button onClick={()=>props.deleteTask(props.columnId,props.task.id,props.index)} style={{position:'absolute', right:0, top:0}} size='small' type='ghost'>x</Button>
                        <div onDoubleClick={()=>setEdit(true)} {...provided.dragHandleProps}>
                            {
                                edit?
                                    <TextArea onBlur={handleOnBlur} onChange={(e)=>setTaskContent(e.target.value)} autoSize value={taskContent} style={{fontWeight:600}} onKeyDown={handleKeyDown} variant='borderless'/>
                                    :
                                    <Text style={{fontWeight:600}} delete={props.columnId === 'done'}>{taskContent}</Text>
                            }
                            
                        </div>
                    </div>
                )
            }
            
        </Draggable>
        
    )
}