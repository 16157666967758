import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";

export default function Kanban() {

    const [data,setData] = useState()
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        setLoading(true)
        async function dbLoader(){
                setData({
                    tasks : {
                        '0': {
                            id: '0',
                            content: 'Drag and Drop the cards!!!!'
                        } ,
                        '1': {
                            id: '1',
                            content: 'To edit a task, double click on it - make your changes and then press return key or click outside this box'
                        } ,
                        '2':{
                            id:'2',
                            content: 'Every column will give you a count of how much task is left.'
                        },
                        '3':{
                            id:'3',
                            content: 'You can remove the tasks once they are done by clicking the Cross button'
                        } ,
                        '4':{
                            id:'4',
                            content: 'Have fun in life! (This was supposed to be a joke)'
                        } 
                    },
                    columns: {
                        'todo' : {
                            id: 'todo',
                            title: 'To Do',
                            taskIds: ['0','1']
                        },
                        'inProgress' : {
                            id: 'inProgress',
                            title: 'In Progress',
                            taskIds: ['2']
                        },
                        'done' : {
                            id: 'done',
                            title: 'Done',
                            taskIds: ['3']
                        },
                        'backlog' : {
                            id: 'backlog',
                            title: 'Backlog',
                            taskIds: ['4']
                        },
                    },
                    columnOrder : ['todo','inProgress','done','backlog']
                })

            setLoading(false)
        }

        dbLoader()


        return () =>{
            setLoading(false)
        }
    },[])

    const onDragEnd=(result)=>{

        const {source, destination, draggableId} = result;

        if(!destination){
            return
        }

        if(
            destination.droppableId === source.droppableId &&
            source.index === destination.index
        ){
            return
        }

        if(destination.droppableId === source.droppableId){
            const column = data.columns[source.droppableId]
            const newTaskIds = Array.from(column.taskIds)

            newTaskIds.splice(source.index,1)
            newTaskIds.splice(destination.index,0,draggableId)

            const newColumn = {
                ...column,
                taskIds: newTaskIds
            }

            const newData = {
                ...data,
                columns: {
                    ...data.columns,
                    [newColumn.id]: newColumn
                }
            }

            setData(newData)
            // call endpoint
            return;
        }
        else{
            const sourceColumn = data.columns[source.droppableId]
            const destinationColumn = data.columns[destination.droppableId]

            const newSourceColumnTaskIds = Array.from(sourceColumn.taskIds)
            const newDestinationColumnTaskIds = Array.from(destinationColumn.taskIds)

            newSourceColumnTaskIds.splice(source.index,1)
            newDestinationColumnTaskIds.splice(destination.index,0,draggableId)

            const newSourceColumn = {
                ...sourceColumn,
                taskIds: newSourceColumnTaskIds
            }

            const newDestinationColumn = {
                ...destinationColumn,
                taskIds: newDestinationColumnTaskIds
            }

            const newData = {
                ...data,
                columns:{
                    ...data.columns,
                    [newSourceColumn.id]: newSourceColumn,
                    [newDestinationColumn.id]: newDestinationColumn
                }
            }

            setData(newData)
            return
        }
    }

    const deleteTask= async (columnId,taskId,index)=>{
        //
        const newTaskIds = data.columns[columnId].taskIds;
        newTaskIds.splice(index,1)

        const newColumn = {
            ...data.columns[columnId],
            taskIds: newTaskIds
        }

        const newTasks = data.tasks;
        delete newTasks[taskId]

        const newData = {
            ...data,
            tasks: newTasks,
            columns:{
                ...data.columns,
                [newColumn.id]: newColumn
            }
        }
        setData(newData)
    }   

    const addTask= async (taskContent)=>{
        // create new task

        const taskId = Date.now().toString();

        const newTask = {
            id: taskId,
            content: taskContent
        }

        const newTodoTaskIds = data.columns['todo'].taskIds
        newTodoTaskIds.splice(newTodoTaskIds.length,0,taskId)

        const newTodoColumn = {
            ...data.columns['todo'],
            taskIds: newTodoTaskIds
        }

        const newData = {
            ...data,
            tasks:{
                ...data.tasks,
                [newTask.id]:newTask
            },
            columns:{
                ...data.columns,
                [newTodoColumn.id]:newTodoColumn
            }
        }
        setData(newData)
        // make db call
    }

    const updateTask = async (taskId,taskContent) =>{
        const newData = {
            ...data,
            tasks:{
                ...data.tasks,
                [taskId]:{
                    id:taskId,
                    content: taskContent
                }
            }
        }
        setData(newData)
    }

    if(loading){
        return (<></>)
    }
    else {
    
        return(
            <DragDropContext onDragEnd={onDragEnd}>
                <div style={{display:'flex', flexDirection:'row',flex:1, width:'100%', height:'100%'}}>
                    {
                        data.columnOrder.map((columnId)=>{
                            const column = data.columns[columnId]
                            const tasks = column.taskIds.length? column.taskIds.map((taskId)=>data.tasks[taskId]) : []
                
                            return <Column updateTask={updateTask} key={column.id} column={column} tasks={tasks} addTask={addTask} deleteTask={deleteTask}/>
                        })
                    }
                </div>
            </DragDropContext>
            
            
        )
    }
}