import React from "react";

export default function Welcome () {


    return (
        <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', textWrap:'wrap'}}>
            <div style={{width:'80%', textAlign:'center'}}>
                <h1>Welcome to Thinker</h1>
                <p>Thinker is a minimal brainstorming app which helps you quily ideate, make sticky notes, write scratch notes as well as plan todo's and sprints.</p>
                <p style={{color:'red', fontSize:16}}><b>This is only a Playground so no Data will be saved!</b></p>
                <h4>Start by Selecting a project in the drop down above!</h4>
            </div>
        </div>
    )
}