import './App.css';
import Root from './routes/root';

function App() {
  return (
    <Root/>
  );
}

export default App;
