import { GlobalOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Radio, Row, Select } from 'antd';
import React, { useState } from 'react';
import Board from '../components/board';
import Doc from "../components/doc";
import Kanban from "../components/kanban";
import Welcome from '../components/welcome';
// import { supabaseClient } from '../supabase/supabase';

export default function Root() {

    const [selectedProject,setSelectedProject] = useState(0)
    const [currentTab,setCurrentTab] = useState('boards')

    const onChange = (value) => {
      setSelectedProject(value)
      setCurrentTab('boards')
    };
    const onSearch = (value) => {
    };
    
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    // to delete later

   const options = [
    {
      label:'Hello world',
      value:0
    }
   ]

    const handleTabChange = (event)=>{
        // eslint-disable-next-line default-case
        switch(event.target.value){
          case "boards":
            setCurrentTab('boards')
            break;
          case "docs":
            setCurrentTab('docs')
            break;
          case "kanbans":
            setCurrentTab('kanbans')
            break;
        }
    }

    const getTab = ()=>{
      switch(currentTab){
        case 'boards':
          return <Board/>
        case 'kanbans':
          return <Kanban/>
        case 'docs':
          return <Doc/>;
        default:
          <Welcome/>
      }
    }

    return (
      <div id='main' style={{width:'100%', display:'flex', flexDirection:'column'}}>
        <Row style={{width:'100%', zIndex:20, backgroundColor:'#ffffff', top:0, paddingRight:10, paddingLeft:10, paddingTop:5, paddingBottom:10}}>
            <Col span={8}>
                <div>
                  <span>Project</span>&nbsp;&nbsp;
                  <Select
                    showSearch
                    style={{width:'250px'}}
                    size='large'
                    placeholder="Select a Project"
                    variant='outlined'
                    optionFilterProp="children"
                    defaultValue={0}
                    onChange={onChange}
                    onSearch={onSearch}
                    value={selectedProject?.id}
                    filterOption={filterOption}
                    options={options}
                  />&nbsp;&nbsp;
              </div>
            </Col>
            <Col span={8} style={{display:'flex',flexDirection:'row',justifyContent:'center', alignItems:'center'}}>
              <Flex vertical gap="middle">
                <Radio.Group disabled={selectedProject===null} defaultValue="boards" onChange={handleTabChange} value={currentTab} buttonStyle="solid">
                  <Radio.Button value="boards">Whiteboard</Radio.Button>
                  <Radio.Button value="docs">Scratchpad</Radio.Button>
                  <Radio.Button value="kanbans">Kanban Board</Radio.Button>
                </Radio.Group>
              </Flex>
            </Col>
            <Col span={8} style={{display:'flex',flexDirection:'row',justifyContent:'flex-end', alignItems:'center'}}>
              
            </Col>
        </Row>
        <div style={{flex:1, height:'100%'}} id="detail">
          {
            getTab()
          }
        </div>
        {
          !currentTab?
            <Welcome/>
            :
            null
        }
      </div>
    );
  }


