/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import ReactQuill from 'react-quill'
import {
	HTMLContainer,
	Rectangle2d,
	ShapeUtil,
	T,
	createShapePropsMigrationIds,
	createShapePropsMigrationSequence,
	resizeBox,
	structuredClone,
	useIsEditing
} from 'tldraw'
import './docShape.css'

const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote', 'link'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'script','code-block'
]

const versions = createShapePropsMigrationIds(
	// this must match the shape type in the shape definition
	'DocShape',
	{
		AddText: 1,
	}
)

// [2]
export const cardShapeMigrations = createShapePropsMigrationSequence({
	sequence: [
		{
			id: versions.AddText,
			up(props) {
				// it is safe to mutate the props object here
				props.text = ''
			},
			down(props) {
				delete props.text
			},
		},
	],
})

export class DocShapeUtil extends ShapeUtil {
	// [2]
	static type = 'DocShape'
	static props = {
		w: T.number,
		h: T.number,
		text: T.string
	}

	static  migrations = cardShapeMigrations

	constructor(props){
		super(props)
	}

	// [3]
	isAspectRatioLocked = (_shape) => false
	canResize = (_shape) => true
	canBind = (_shape) => true

	// [4]
	canEdit = () => true

	canScroll = () => true

	// [5]
	getDefaultProps() {
		return {
			w: 500,
			h: 400,
			text: ''
		}
	}

	// [6]
	getGeometry(shape) {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		})
	}

	

	// [7]
	component(shape) {
		const isEditing = useIsEditing(shape.id)

  		return (
			<HTMLContainer id={shape.id} onScroll={(e)=>e.stopPropagation()}>
				<div style={{
					height:'100%',
					pointerEvents:'all',
					overflowY:'hidden',
					backgroundColor:'#fefefe',
					position:'relative'
				}}
				onPointerDown={(e)=>{
					if(isEditing){
						e.stopPropagation()
					}
				}}
				onScroll={(e)=>{
					e.stopPropagation()
				}}	
				>
          			<ReactQuill style={{fontSize:22}} readOnly={!isEditing} theme="snow" value={shape.props.text} modules={modules} formats={formats} 
						onChange={(content, delta, source, editor)=>{
							this.editor.updateShape({
								id: shape.id,
								type: shape.type,
								props: {
									...shape.props,
									text: content
								},
							})
						}} 
					/>
					{
						!isEditing? <span style={{position:'absolute', bottom:5, right:5, fontSize:12, fontWeight:700}}>Double click to Edit</span> : null
					}
				</div>
			</HTMLContainer>
  		)
	}

	// [8]
	indicator(shape) {
		const isEditing = useIsEditing(shape.id)
		return <rect stroke={isEditing ? 'red' : 'blue'} width={shape.props.w} height={shape.props.h} />
	}

	// [9]
	onResize = (shape, info) => {
		if(info.initialBounds.w * info.scaleX >= 500 && info.initialBounds.h * info.scaleY >= 400) {
			return resizeBox(shape, info)
		}
	}

	// [10]
	onEditEnd = (shape) => {
		const frame1 = structuredClone(shape)
		const frame2 = structuredClone(shape)

		frame1.x = shape.x + 1.2
		frame2.x = shape.x - 1.2

		this.editor.animateShape(frame1, { duration: 50 })

		setTimeout(() => {
			this.editor.animateShape(frame2, { duration: 50 })
		}, 100)

		setTimeout(() => {
			this.editor.animateShape(shape, { duration: 100 })
		}, 200)
	}
}
