import React from 'react'
import {
	DefaultKeyboardShortcutsDialog,
	DefaultKeyboardShortcutsDialogContent,
	DefaultToolbar,
	DefaultToolbarContent,
	TldrawUiMenuItem,
	useTools,
	useIsToolSelected
} from 'tldraw'

// There's a guide at the bottom of this file!

export const uiOverrides = {
	tools(editor, tools) {
		// Create a tool item in the ui's context.
		tools.DocShape = {
			id: 'DocShape',
			icon: 'tool-file',
			label: 'Add Document',
			kbd: 'j',
			onSelect: () => {
				editor.setCurrentTool('DocShape')
			},
		}
		return tools
	}
}

export const components = {

	Toolbar: (props) =>{
		const tools = useTools()
		const isCardSelected = useIsToolSelected(tools['DocShape'])
		return (
			<DefaultToolbar {...props}>
				<TldrawUiMenuItem {...tools['DocShape']} isSelected={isCardSelected} />
				<DefaultToolbarContent />
			</DefaultToolbar>
		)
	},
	KeyboardShortcutsDialog: (props) => {
		const tools = useTools()
		return (
			<DefaultKeyboardShortcutsDialog {...props}>
				<DefaultKeyboardShortcutsDialogContent />
				{/* Ideally, we'd interleave this into the tools group */}
				<TldrawUiMenuItem {...tools['DocShape']} />
			</DefaultKeyboardShortcutsDialog>
		)
	},
}

export const customAssetUrls = {
	icons: {
		'tool-file': 'https://unpkg.com/@tldraw/assets@2.0.2/icons/icon/page.svg',
	},
}
