import { Badge, Button, Popconfirm } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Task from './Task'


const getColor=(columnId) =>{
    switch(columnId){
        case 'todo':
            return 'lightcoral';
        case 'inProgress':
            return 'lightskyblue';
        case 'done':
            return 'lightgreen';
        case 'backlog':
            return 'yellow'
    }
}

export default function Column(props) {
    const [taskContent,setTaskContent] = useState("")

    return (
        <div 
            style={{
            margin:'4px',
            border:'1px solid lightgrey',
            borderRadius: '2px',
            width:'100%',
            minWidth: '250px',
            flex:1
        }}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderBottom:'1px solid lightgrey', marginLeft:'8px', marginRight:'8px', position:'sticky', top:0, zIndex:100, backgroundColor:"#ffffff"}}>
                
                    <div style={{paddingTop:18, paddingBottom:10}}>
                        <Badge color={props.column.id==='done'?'green':'red'} count={props.tasks.length}>
                            <div style={{paddingLeft:'10px',paddingRight:'10px',paddingTop:props.tasks.length?'14.5px':'11px',paddingBottom:props.tasks.length?'14.5px':'11px',backgroundColor:getColor(props.column.id), borderRadius:20,fontWeight:600}}>{props.column.title}</div>
                        </Badge>
                    </div>
                
                
                {
                    props.column.id === 'todo'? 
                    <Popconfirm
                        placement="bottom"
                        title={"Add New Task"}
                        description={()=>(
                        <div style={{marginTop:10}}>
                            <TextArea
                                placeholder="Write Task content"
                                autoSize={{ minRows: 2}}
                                value={taskContent}
                                onChange={(e)=>setTaskContent(e.target.value)}
                            />
                        </div>
                        )}
                        okText="Add"
                        showCancel={false}
                        icon={null}
                        onOpenChange={()=>setTaskContent('')}
                        onConfirm={()=>props.addTask(taskContent)}
                    >
                        <Button size='medium'>Add task</Button>
                    </Popconfirm> : 
                    <></>
                }
            </div>
            
            <Droppable droppableId={props.column.id}>
                {
                    (provided,snapshot) =>(
                        <div {...provided.droppableProps} ref={provided.innerRef} style={{padding:'8px',backgroundColor:snapshot.isDraggingOver?getColor(props.column.id):'#fefefe',height:'92%'}} >
                            {props.tasks.map((task,index)=><Task updateTask={props.updateTask} key={task.id} task={task} index={index} columnId={props.column.id} deleteTask={props.deleteTask}/>)}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
            
        </div>
    )
}